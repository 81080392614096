<template>
    <div>
        <el-dialog :title=title :visible.sync="isShow" width="360px" custom-class="resultDialog dataM"
            :before-close="handleClose">
            <div class="tip">
                <div class="title">选择工单号</div>
                <el-select v-model="value" placeholder="" @change="optionFn">
                    <el-option :label="item.num" :value="item.num" v-for="(item, index) in mergeData" :key="index">
                    </el-option>
                </el-select>
                <el-date-picker v-model="date" value-format="yyyy-MM-dd" type="date" placeholder="选择日期">
                </el-date-picker>
                <div class="footer">
                    <el-button @click="handleClose" style="color:#B9B9B9">取消</el-button>
                    <el-button type="primary" @click="OK">确定</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { incorporateOrder } from '@/api/orders.js'
export default {
    data() {
        return {
            value: '',
            date: '',
            id: ''
        }
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        isShow: {
            type: Boolean,
            default: false
        },
        mergeData: {
            type: Array
        }
    },
    methods: {
        // 关闭弹框
        handleClose() {
            this.$emit('closeMerge')
            this.value = ''
            this.date = ''
        },
        async OK() {
            let tempId = []
            for (let i = 0; i < this.mergeData.length; i++) {
                if (this.mergeData[i].id) {
                    tempId.push(this.mergeData[i].id)
                }
            }
            let params = {
                ids: tempId,
                selectId: Number(this.id),
                orderCompletionDate: this.date
            }
            const res = await incorporateOrder(params)
            if (res.code === 0) {
                this.$message.success('工单合并成功！')
            } else {
                this.$message.warning(res.msg)
            }
            this.$emit('OKMerge')
            this.value = ''
            this.date = ''
        },
        optionFn() {
            this.date = this.mergeData.filter(v => this.value === v.num)[0].date
            this.id = this.mergeData.filter(v => this.value === v.num)[0].id
        }
    }
}
</script>

<style lang="scss" scoped>
.resultDialog {
    img {
        width: 70px;
        height: 70px;
    }

    .tip {
        padding: 3px 7px 3px 7px;

        div {
            line-height: 40px;
            color: #B9B9B9;
        }

        .title {
            font-size: 16px;
            text-align: left;
            color: #E8E8E8;
        }

        .el-button {
            color: #fff;
            width: 80px;
            height: 40px;
        }
    }
}

.footer {
    text-align: right;
    margin-top: 12px;
}

::v-deep .el-dialog__header {
    text-align: left;
    padding-top: 25px !important;
    padding-left: 25px !important;

    .el-dialog__title {
        font-size: 22px;
        // margin-left: 15px;
    }
}

::v-deep .el-select {
    width: 100%;
    margin-bottom: 8px;
}

::v-deep .el-autocomplete {
    width: 100%;
}

::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}


::v-deep .el-date-editor {
    .el-input__prefix {
        right: 0px !important;
        left: auto;
        margin-right: 5px;
    }

    .el-input__inner {
        padding: 0 15px;
    }
}
</style>